import React from "react"
import { Row, Col, Typography } from "antd"
const { Title, Paragraph } = Typography

const BosPrivacyPolice = () => {
  return (
    <Row justify="center" style={{ margin: "70px 0" }}>
      <Col span={20}>
        <Title level={3} className="text-center">
          Kebijakan Privasi
        </Title>
      </Col>
      <Col span={18}>
        <Title level={4} className="mtop-40">
          Data Pribadi
        </Title>
        <Paragraph>
          Untuk dapat menggunakan layanan BOS, pengguna perlu melakukan
          registrasi dan melengkapi informasi seperti nama, nomor handphone,
          email, privinsi, kota / kabupaten, foto ktp. Pengguna menjamin bahwa
          seluruh data yang diberikan adalah data yang valid dan sah. BOS
          menggunakan data pengguna untuk memudahkan proses verifikasi pengguna
          demi menjaga keamanan seluruh pengguna BOS. Bila terdapat data-data
          yang tidak valid dan sah, BOS tidak bertanggung jawab atas segala
          akibat yang terjadi sehubungan dengan pemberian informasi yang tidak
          benar.
        </Paragraph>
        <Title level={4} className="mtop-40">
          Penggunaan Data Pribadi
        </Title>
        <Paragraph>
          Semua data diberikan secara sukarela oleh pengguna BOS saat melakukan
          registrasi dan disimpan dengan aman oleh pihak BOS. Pengguna setuju
          untuk memberikan izin bagi BOS dalam menggunakan data-data tersebut
          untuk meningkatkan pengalaman pengguna dalam menggunakan seluruh
          layanan dari BOS, termasuk dalam membagikan data tersebut pada pihak
          ketiga yang bekerjasama dengan BOS.
        </Paragraph>
        <Title level={4} className="mtop-40">
          Keamanan Data
        </Title>
        <Paragraph>
          BOS berkomitmen untuk menjaga keamanan data pengguna. Seluruh
          informasi pribadi pengguna akan selalu dilindungi oleh BOS. Kebijakan
          privasi ini berlaku untuk seluruh produk dan layanan yang dioperasikan
          oleh. BOS memberikan batasan akses kepada staff untuk segala data
          pribadi yang dimiliki oleh pengguna dan tidak memperbolehkan untuk
          membawa data keluar dan digunakan untuk kepentingan pribadi.
        </Paragraph>
        <Title level={4} className="mtop-40">
          Bantuan Pengguna
        </Title>
        <Paragraph>
          BOS menyediakan bantuan pengguna 24 jam dalam bentuk percakapan
          langsung via aplikasi atau bisa mengirimkan email ke alamat
          admin@wis-pay.com
        </Paragraph>
        <Title level={4} className="mtop-40">
          Penggunaan Permisi Khusus
        </Title>
        <Paragraph>
          BOS menggunakan permisi ke kamera pengguna untuk memberikan kemampuan
          bagi pengguna dalam mengambil gambar menggunakan kamera pada perangkat
          pengguna. Gambar tersebut digunakan untuk penggunaan aplikasi seperti
          mengunggah foto profil.
        </Paragraph>
        <Paragraph>
          BOS menggunakan permisi lokasi untuk memberikan kemampuan bagi
          pengguna dalam menggunakan fitur peta pada aplikasi BOS.
        </Paragraph>
        <Paragraph>
          BOS menggunakan permisi daftar kontak untuk mempermudah pengguna dalam
          melakukan input data seperti nama, nomor handphone, email, dalam
          penggunaan fitur dan transaksi di BOS.
        </Paragraph>
      </Col>
    </Row>
  )
}

export default BosPrivacyPolice
