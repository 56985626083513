import React from "react"
import "resources/style/style.less"
import Seo from "../components/seo"
import Layout from "components/layout"
import BosPrivacyPolice from "components/section/bos-privacy-police"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Privacy Policy Wispay"} />
      <Layout headerVariant="blue" headerBackground="blue">
        <BosPrivacyPolice />
      </Layout>
    </>
  )
}

export default IndexPage
